import React from 'react';
import Button from './molecules/Button';
import SmallTitle from './type/SmallTitle';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function SpeltakCard({
  desc,
  title,
  image,
  className,
  url,
  animationDelay,
}) {
  const icon = getImage(image);

  return (
    <div
      data-sal="slide-left"
      data-sal-duration="400"
      data-sal-delay={animationDelay}
      className={`disable-sal z-30 mx-5 flex-shrink-0 flex-grow-0 basis-auto snap-center overflow-hidden ${className}`}
    >
      <div className="w-64 shadow md:w-56">
        <GatsbyImage className="relative z-10" image={icon} alt="speltak" />
        <div className="relative z-0 -mt-28 min-h-[475px] bg-white pl-5 pr-5 pt-28">
          <SmallTitle title={title} className="text-center leading-[57px]" />
          <p className="pb-16 font-body">{desc}</p>
          <Button
            className="absolute mb-0 bottom-5"
            type={true}
            url={url}
            text="Lees meer"
          />
        </div>
      </div>
    </div>
  );
}
