import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Sponsor({
  className,
  logo,
  link,
  name,
  animationDelay,
}) {
  const logoImg = getImage(logo);

  return (
    <div
      data-sal="slide-down"
      data-sal-duration="400"
      data-sal-delay={animationDelay}
      className="w-full mb-5 md:mt-0"
    >
      <p className="pb-3 text-sm text-center text-gray-400 font-body">{name}</p>
      <a href={link} target="_blank">
        <div
          className={`flex h-64 items-center justify-center border-8 border-red ${className}`}
        >
          <GatsbyImage image={logoImg} />
        </div>
        <p className="hidden">{name}</p>
      </a>
    </div>
  );
}
