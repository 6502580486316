import React, { useRef } from 'react';
import useDragScroll from '../hooks/useDragScroll.js';
import Layout from '../components/layouts/Layout.js';
import Title from '../components/type/Title.js';
import Text from '../components/type/Text.js';
import Container from '../components/layouts/Container.js';
import SpeltakCard from '../components/SpeltakCard.js';
import WoodSpacer from '../components/molecules/WoodSpacer.js';
import Blog from '../components/BlogHomeCard.js';
import Button from '../components/molecules/Button.js';
import DotSpacer from '../components/molecules/DotSpacer.js';
import Sponsor from '../components/Sponsor.js';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import ImageSlider from '../components/molecules/ImageSlider.js';
//import Snowfall from 'react-snowfall';
import { FAQJsonLd } from 'gatsby-plugin-next-seo';

// markup
const IndexPage = ({ data }) => {
  const ref = useRef(null);

  useDragScroll({
    sliderRef: ref,
  });

  const blogs = data.allWpPost.nodes;
  const speltakken = data.allWpSpeltak.nodes;
  const components = data.wpPage.components.components;
  const seo = data.wpPage.seo.seoData;
  const teamImage = getImage(components[2].normalimage.localFile);
  const teamImageHout = 'hout2-min.png';

  const delay = 100;

  return (
    <>
      {/* <Snowfall
        snowflakeCount={150}
        style={{
          position: 'absolute',
          width: '100vw',
          height: '200%',
          zIndex: '99999',
        }}
      /> */}

      <GatsbySeo
        title={seo.title}
        description={seo.description}
        openGraph={{
          type: 'website',
          images: [
            {
              url: data.wpPage.featuredImage.node.localFile.url,
              width: 800,
              height: 600,
              alt: 'Scouting Graaf Daron',
            },
          ],
          url: 'https://graafdaron.nl',
          title: 'Scouting Graaf Daron',
          description: seo.description,
        }}
      />
      <Layout>
        {/* <Container className="flex flex-wrap items-center justify-between py-8 contain md:flex-nowrap md:py-16">
          <div className="w-full sm:w-1/2 sm:pr-5 md:w-7/12 md:pr-16">
            <Title
              title={components[0].title}
              subtitle={components[0].subtitle}
            />
            <Text text={components[1].text} />
          </div>
          <div className="w-full mt-6 sm:w-1/2 sm:pl-5 md:w-5/12 md:pl-0">
            <GatsbyImage
              image={teamImage}
              alt={components[2].normalimage.altText}
            />
          </div>
        </Container> */}
        <Container className="flex flex-wrap items-start pt-0 pb-0 pl-0 pr-0 mt-0 mb-20 md:mt-20 md:flex-nowrap md:px-5">
          <section className="md:w-7/12">
            <section className="px-5 -mb-40 bg-red-pink pb-44 pt-7 md:-mr-44 md:mb-0 md:pl-7 md:pb-7 md:pr-52">
              <Title
                title={components[0].title}
                subtitle={components[0].subtitle}
              />
              <Text text={components[1].text} />
            </section>
            <div className="px-5 pt-10 md:pb-7 md:pl-7">
              <Title title="Wat doen we?" />
              <Text text="Bij Scouting Graaf Daron in Alkmaar kan ieder kind terecht voor een te gekke wekelijkse bijeenkomst. Je kan al lid worden bij ons vanaf 4 jaar oud! Wat we dan zoal doen bij Scouting? Van alles! We bouwen alles wat we kunnen verzinnen, knopen de mooiste dingen aan elkaar, gaan minstens 2 keer kamperen per jaar, koken de lekkerste gerechten en gaan met flinke snelheden van onze tokkelbanen af. Maar dat is zeker nog niet alles, want Scouting moet je vooral eens ervaren. En dit alles wordt mogelijk gemaakt door ons <a class='underline text-red' href='/bestuur'>fantastische team vrijwilligers.</a>" />
              <Button type={false} text="Kom een keer langs!" url="/contact" />
            </div>
          </section>
          <img
            src={teamImageHout}
            alt="Hout"
            className="relative z-10 order-2 hidden w-5/12 md:block"
          />
        </Container>
        <div className="-mb-2 bg-white skyline"></div>
        <section className="h-auto pb-10 speltakken-home md:h-96">
          <Container className="pt-7">
            <Title
              title={components[3].title}
              subtitle={components[3].subtitle}
              white={components[3].white}
            />
          </Container>
          <section
            ref={ref}
            className="flex justify-between overflow-x-auto scrollbar-hidden cursor-based-on-overflow md:contain contain snap-x snap-mandatory flex-nowrap md:snap-none"
          >
            {speltakken.map((speltak, index) => {
              return (
                <SpeltakCard
                  animationDelay={index * 100}
                  image={speltak.speltakken.logo.localFile}
                  title={speltak.title}
                  desc={speltak.speltakken.description}
                  url={`/speltakken/${speltak.slug}`}
                  className="ml-5"
                />
              );
            })}
          </section>
        </section>
        {components[4].fieldGroupName ? (
          <WoodSpacer className={'md:h-[485px]'} />
        ) : (
          ''
        )}
        
        <Container>
          <div className="mt-8 md:mt-16">
            <Title
              title={components[5].title}
              subtitle={components[5].subtitle}
              white={components[5].white}
            />
          </div>
          <section className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            {blogs.map((blog, index) => {
              return (
                <Blog
                  animationDelay={index * 200}
                  key={blog.id}
                  title={blog.title}
                  image={blog.featuredImage ?? ''}
                  excerpt={blog.excerpt}
                  url={blog.slug}
                />
              );
            })}
          </section>
          <Button
            className="mt-6 md:mt-12"
            url={components[6].button.url}
            text={components[6].button.title}
          />
<DotSpacer />
<section className="h-auto pb-10">
            <Title
              title={'Veel gestelde vragen'}
              subtitle={'Wat wil je weten?'}
              white={false}
            />
          <div>
            <FAQJsonLd
                questions={[
                  {
                    question: 'Hoe oud moet je zijn voor Scouting?',
                    answer: 'Je kan al lid worden bij Scouting vanaf 5 jaar. We hebben voor alle leeftijdscategorieën een zogeheten speltak, die je hierboven vindt.',
                  },
                  {
                    question: 'Wat voor Scouting zijn er?',
                    answer: 'Er zijn verschillende soorten Scoutinggroepen: land-, water- en luchtscouting. Ze doen allemaal hun eigen activiteiten op de Scouting manier.',
                  },
                  {
                    question: 'Wat doen kinderen bij de Scouting?',
                    answer: 'Scouts zijn actief en houden ervan nieuwe dingen te proberen. We beleven avonturen zoals kamperen, wandeltochten maken, zwemmen, abseilen, tokkelen en mountainbiken. Elke week ontmoeten we onze vrienden, waarmee we samen veel plezier maken, spelletjes spelen, in teamverband werken en ons storten op nieuwe uitdagingen.',
                  },
                  {
                    question: 'Hoeveel kost Scouting per maand of per jaar?',
                    answer: 'De contributie van Scouting Graaf Daron is €110,- per jaar. Dit komt neer op ongeveer €10,- per maand. Dit is dan exclusief weekendjes weg en zomerkamp.',
                  },
                  {
                    question: 'Is Scouting goed voor de ontwikkeling van mijn kind?',
                    answer: 'Nederlandse Scouting-leden zijn actiever en milieubewuster dan niet-leden, en blinken uit in sociale ontwikkeling, zoals hulpvaardigheid, maatschappelijke betrokkenheid en openheid voor culturen. Ze melden groei in samenwerking, leiderschap, organisatievermogen, zelfstandigheid, sociaal gedrag en zelfvertrouwen door hun Scouting-ervaringen, essentieel voor gezonde ontwikkeling naar volwassenheid. Bron: Impactonderzoek Scouting Nederland.',
                  },
                ]}
              />

              <section className="md:max-w-[80%]  mb-5">
                <h2 className="text-lg font-semibold leading-8 font-body">Hoe oud moet je zijn voor Scouting?</h2>
                <p className="text-base font-light leading-8 font-body">Je kan al lid worden bij Scouting vanaf 5 jaar. We hebben voor alle leeftijdscategorieën een zogeheten speltak, die je hierboven vindt.</p>
              </section>
              <section className="md:max-w-[80%]  mb-5">
                <h2 className="text-lg font-semibold leading-8 font-body">Wat voor Scouting zijn er?</h2>
                <p className="text-base font-light leading-8 font-body">Er zijn verschillende soorten Scoutinggroepen: land-, water- en luchtscouting. Ze doen allemaal hun eigen activiteiten op de Scouting manier.</p>
              </section>
              <section className="md:max-w-[80%]  mb-5">
                <h2 className="text-lg font-semibold leading-8 font-body">Wat doen kinderen bij de Scouting?</h2>
                <p className="text-base font-light leading-8 font-body">Scouts zijn actief en houden ervan nieuwe dingen te proberen. We beleven avonturen zoals kamperen, wandeltochten maken, zwemmen, abseilen, tokkelen, mountainbiken. Elke week ontmoeten we onze vrienden, waarmee we samen veel plezier maken, spelletjes spelen, in teamverband werken en ons storten op nieuwe uitdagingen.</p>
              </section>
              <section className="md:max-w-[80%]  mb-5">
                <h2 className="text-lg font-semibold leading-8 font-body">Hoeveel kost Scouting per maand of per jaar?</h2>
                <p className="text-base font-light leading-8 font-body">De contributie van Scouting Graaf Daron is €110,- per jaar. Dit komt neer op ongeveer €10,- per maand. Dit is dan exclusief weekendjes weg en zomerkamp.</p>
              </section>
              <section className="md:max-w-[80%]  mb-5">
                <h2 className="text-lg font-semibold leading-8 font-body">Is Scouting goed voor de ontwikkeling van mijn kind?</h2>
                <p className="text-base font-light leading-8 font-body">Nederlandse Scouting-leden zijn actiever en milieubewuster dan niet-leden, en blinken uit in sociale ontwikkeling, zoals hulpvaardigheid, maatschappelijke betrokkenheid en openheid voor culturen. Ze melden groei in samenwerking, leiderschap, organisatievermogen, zelfstandigheid, sociaal gedrag en zelfvertrouwen door hun Scouting-ervaringen, essentieel voor gezonde ontwikkeling naar volwassenheid. Bron: <a href="https://www.scouting.nl/assets/uploads/doorzoekbareBestanden/Impactonderzoek-Scouting.pdf">Impactonderzoek Scouting Nederland</a>.</p>
              </section>
          </div>
        </section>

          {components[7].fieldGroupName ? <DotSpacer /> : ''}
          <Title
            title={components[8].title}
            subtitle={components[8].subtitle}
            white={components[8].white}
            center={components[8].center}
          />
          <section className="grid grid-cols-1 gap-5 lg:grid-cols-3">
            <Sponsor
              animationDelay={'100'}
              name={components[9].name}
              logo={components[9].logo.localFile}
              link={components[9].link.url}
            />
            <Sponsor
              animationDelay={'200'}
              name={components[10].name}
              logo={components[10].logo.localFile}
              link={components[10].link.url}
            />
            <Sponsor
              animationDelay={'300'}
              name={components[11].name}
              logo={components[11].logo.localFile}
              link={components[11].link.url}
            />
          </section>
          <p
            className={`text-center font-body text-base font-light italic leading-8`}
          >
            - Ook sponsor worden? Neem{' '}
            <Link to="/contact" className="underline text-blue">
              contact
            </Link>{' '}
            op! -
          </p>
          {components[12].fieldGroupName ? <DotSpacer /> : ''}
        </Container>
        <ImageSlider />
      </Layout>
    </>
  );
};

export const query = graphql`
  query IndexQuery {
    wpPage(isFrontPage: { eq: true }) {
      title
      seo {
        seoData {
          description
          title
        }
      }
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      components {
        components {
          ... on WpPage_Components_Components_Title {
            title
            subtitle
            center
            white
          }
          ... on WpPage_Components_Components_Text {
            text
          }
          ... on WpPage_Components_Components_Woodspacer {
            fieldGroupName
          }
          ... on WpPage_Components_Components_Button {
            button {
              target
              title
              url
            }
          }
          ... on WpPage_Components_Components_Dotspacer {
            fieldGroupName
          }
          ... on WpPage_Components_Components_Sponsor {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 300)
                }
              }
            }
            link {
              url
            }
          }
          ... on WpPage_Components_Components_Normalimage {
            normalimage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 500, quality: 80)
                }
              }
            }
          }
        }
      }
    }
    allWpPost(limit: 3, sort: { order: DESC, fields: date }) {
      nodes {
        excerpt
        title
        id
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 500, quality: 80)
              }
            }
          }
        }
      }
    }
    allWpSpeltak(sort: { fields: date, order: ASC }) {
      nodes {
        title
        speltakken {
          description
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 500, quality: 80)
              }
            }
          }
          times
        }
        slug
      }
    }
  }
`;

export default IndexPage;
