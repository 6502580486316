import React from 'react';
import Button from './molecules/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Blog({
  className,
  image,
  title,
  excerpt,
  url,
  animationDelay,
}) {
  let featured = '';

  if (image !== '') {
    const imagegood = getImage(image.node.localFile);
    featured = (
      <GatsbyImage
        image={imagegood}
        alt="blogimage"
        className="object-cover w-full h-64"
      />
    );
  }

  //Excerpt length and dots
  if (excerpt.length > 165) {
    excerpt = excerpt.substring(0, 165);
    excerpt = excerpt + '...';
  }
  const sanExcerpt = () => {
    return {
      __html: excerpt,
    };
  };

  return (
    <article
      data-sal="slide-up"
      data-sal-duration="400"
      data-sal-delay={animationDelay}
      className={`w-full ${className} mb-10 flex flex-col justify-between md:mb-0`}
    >
      <div>
        {featured}
        <div className="w-full p-3 text-white bg-red">
          <h5>{title}</h5>
        </div>
        <p
          dangerouslySetInnerHTML={sanExcerpt()}
          className={`mt-3 mb-5 font-body text-base font-light leading-8 ${className}`}
        ></p>
      </div>
      <Button
        className="items-end"
        url={`/nieuws/${url}`}
        type={true}
        text="Lees meer"
      />
    </article>
  );
}
